
import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import Widget from "@/AsicJungleAnalyticsUI/components/Widget/Widget.vue";
import WidgetBody from "@/AsicJungleAnalyticsUI/components/Widget/WidgetBody.vue";
import Grid from "@/AsicJungleAnalyticsUI/components/composites/Grid.vue";
import WidgetFooter from "@/AsicJungleAnalyticsUI/components/Widget/WidgetFooter.vue";
import Columns from "@/AsicJungleAnalyticsUI/components/composites/Columns.vue";
import ColumnSpacer from "@/AsicJungleAnalyticsUI/components/composites/ColumnSpacer.vue";
import Button from "@/AsicJungleAnalyticsUI/components/content/Button.vue";
import DataSourceFormInput from "@/Classification/components/DataSourceFormInput.vue";
import {DataPointLabel} from "@/Classification/models/DataPointLabel";
import {DataPoint} from "@/Classification/models/DataPoint";

@Component({
  components: {DataSourceFormInput, Button, ColumnSpacer, Columns, WidgetFooter, Grid, WidgetBody, Widget}
})
export default class DataPointForm extends Vue {
  @Prop({type: Array, required: true})
  private readonly labels: DataPointLabel[]

  @Prop({type: Object, required: true})
  private readonly dataPoint: DataPoint

  updated(): void {
    console.debug("DataPointForm updated")
  }

  handleLabelValueUpdated({labelName, labelValue}: {labelName: string, labelValue: any}): void {
    this.dataPoint.labels[labelName] = labelValue
    this.$emit('dataPointUpdated', this.dataPoint)
  }

  handleRemove(): void {
    this.$emit('remove')
  }
}
