
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {DataSource} from "@/Classification/models/DataSource";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import Button from "@/AsicJungleAnalyticsUI/components/content/Button.vue";
import Stack from "@/AsicJungleAnalyticsUI/components/composites/Stack.vue";
import Columns from "@/AsicJungleAnalyticsUI/components/composites/Columns.vue";
import ColumnSpacer from "@/AsicJungleAnalyticsUI/components/composites/ColumnSpacer.vue";
import Widget from "@/AsicJungleAnalyticsUI/components/Widget/Widget.vue";
import WidgetFooter from "@/AsicJungleAnalyticsUI/components/Widget/WidgetFooter.vue";
import Cluster from "@/AsicJungleAnalyticsUI/components/composites/Cluster.vue";
import Label from "@/AsicJungleAnalyticsUI/components/content/Label.vue";
import TextContent from "@/AsicJungleAnalyticsUI/components/content/TextContent.vue";
import Form from "@/AsicJungleAnalyticsUI/components/Form/Form.vue";
import Input from "@/AsicJungleAnalyticsUI/components/Form/Input.vue";
import WidgetHeader from "@/AsicJungleAnalyticsUI/components/Widget/WidgetHeader.vue";
import WidgetBody from "@/AsicJungleAnalyticsUI/components/Widget/WidgetBody.vue";
import Grid from "@/AsicJungleAnalyticsUI/components/composites/Grid.vue";
import {DataPointLabel} from "@/Classification/models/DataPointLabel";
import DataSourceFormInput from "@/Classification/components/DataSourceFormInput.vue";
import DataPointForm from "@/Classification/components/DataPointForm.vue";
import { v4 as uuidv4 } from "uuid";
import LoadingIcon from "@/AsicJungleAnalyticsUI/components/content/LoadingIcon.vue";
import ClassificationApi from "@/Classification/ClassificationApi";
import {ApiResponse} from "@/Core/ApiClient";
import {DataPoint} from "@/Classification/models/DataPoint";
import Notice from "@/AsicJungleAnalyticsUI/components/Notice/Notice.vue";



@Component({
  components: {
    Notice,
    LoadingIcon,
    DataPointForm,
    DataSourceFormInput,
    Grid,
    WidgetBody,
    WidgetHeader,
    Input, Form, TextContent, Label, Cluster, WidgetFooter, Widget, ColumnSpacer, Columns, Stack, Button, Box}
})
export default class DataSourceForm extends Vue {
  @Prop({required: true})
  private readonly dataSource: DataSource

  @Prop({type: Array, required: true})
  private readonly labels: DataPointLabel[]

  private dataPoints: DataPoint[] = []

  private addDataPointsOperationInProgress = false

  private autoClassifyOperationInProgress = false

  private errorMessage: string = null

  private discardOperationInProgress = false

  created(): void {
    this.handleAddDataPoint()
  }

  updated(): void {
    console.debug("DataSourceForm updated")
  }

  get labeledDataPoints(): DataPoint[] {
    // Discard data points with no labels
    return this.dataPoints.filter((d: DataPoint) => Object.keys(d.labels).length !== 0)
  }

  get canSubmit(): boolean {
    return this.labeledDataPoints.length !== 0 && !this.addDataPointsOperationInProgress && !this.autoClassifyOperationInProgress
  }


  formatDataSource(data: string): string {
    return data.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  handleAutoFill(): void {
    this.autoClassifyOperationInProgress = true
    ClassificationApi.generateLLM(this.dataSource.id).then((r: ApiResponse<Array<DataPoint>>) => {
      if (r.data.length == 0) {
        this.$toast.warning("Could not autofill, AI did not identify any labels.")
      } else {
        this.$toast.success("Data source auto classified successfully")
      }

      this.dataPoints = [
        ...r.data,
        ...this.dataPoints
      ]
      // This forces reactivity on nested objects.
      this.dataPoints.push({id: uuidv4(), labels: {}, date: this.dataSource.date})
      this.dataPoints.pop()


    }).catch((r: ApiResponse<null>) => {
      this.errorMessage = r.error.message
    }).finally(() => {
      this.autoClassifyOperationInProgress = false
    })
  }

  handleAddDataPoint(): void {
    this.dataPoints = [
      {
        id: uuidv4(),
        labels: {},
        date: this.dataSource ? this.dataSource.date : null,
      },
      ...this.dataPoints
    ]
  }

  handleDataPointUpdated(dp: DataPoint) {
    for (const dpKey in this.dataPoints) {
      if (this.dataPoints[dpKey].id !== dp.id) {
        continue
      }

      this.dataPoints[dpKey] = dp

      // This forces reactivity on nested objects.
      this.dataPoints.push({id: uuidv4(), labels: {}, date: this.dataSource.date})
      this.dataPoints.pop()

      return
    }
  }

  handleRemoveDataPoint(dp: DataPoint): void {
    this.dataPoints = this.dataPoints.filter((d) => d.id !== dp.id)
  }

  handleSubmit(): void {
    this.addDataPointsOperationInProgress = true

    ClassificationApi.classifyDataSource(this.dataSource.id, this.labeledDataPoints).then(() => {
      this.$toast.success("Data source classified successfully")
      this.$emit('classified')
    }).catch((r: ApiResponse<null>) => {
      this.errorMessage = r.error.message
    }).finally(() => {
      this.addDataPointsOperationInProgress = false
    })
  }

  discardDataSource(): void {
    this.discardOperationInProgress = true
    ClassificationApi.discardDataSource(this.dataSource.id).then(() => {
      this.$toast.success("Data source discarded successfully")
      this.$emit('classified')
    }).catch((r: ApiResponse<null>) => {
      this.$toast.error(r.error.message)
    }).finally(() => {
      this.discardOperationInProgress = false
    })
  }
}
