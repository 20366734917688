
import {Component, Prop, Vue} from "vue-property-decorator";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import {DataPointLabel} from "@/Classification/models/DataPointLabel";
import Input from "@/AsicJungleAnalyticsUI/components/Form/Input.vue";
import MultiInput from "@/AsicJungleAnalyticsUI/components/Form/MultiInput.vue";
import Select, {SelectOption} from "@/AsicJungleAnalyticsUI/components/Form/Select.vue";
import {capitalize} from "lodash";

@Component({
  components: {Select, MultiInput, Input, Box}
})
export default class DataSourceFormInput extends Vue {
    @Prop({type: Object, required: true})
    readonly label: DataPointLabel

    @Prop({required: false})
    readonly value: any

    enumOptions(): SelectOption[] {
      const values = this.label.type.name === "enum" ? this.label.type.values : [];

      return [{
        text: "No value",
        value: null,
      }, ...values.map((v) => ({text: v, value: v}))]
    }

    handleInput(v: any): void {
      this.$emit('input', {labelName: this.label.name, labelValue: v})
    }

    capitalize(v: string): string {
      return capitalize(v)
    }
}
