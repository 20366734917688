import axios, {AxiosError, AxiosInstance, AxiosResponse} from "axios"
import Vue from "vue"

export interface ApiError {
    readonly type: string
    readonly message: string
    readonly data: any
}

/**
 * Represents a response returned by the API.
 */
export class ApiResponse<T> {
    /**
     * @param status status of the response
     * @param data data returned as payload
     * @param error name of the error if any.
     * @param message message accompanying an error if any.
     */
    constructor(
        readonly status: ApiResponseStatus,
        readonly data: T,
        readonly error?: ApiError,
    ) {
        this.status = status
        this.data = data
        this.error = error
    }
}

/**
 * Represents the status of an API response.
 */
export enum ApiResponseStatus {
    SUCCESS = "success",
    FAILED = "failed",
}

/**
 * Represents the result of an interaction with an API.
 * It is simply syntactic sugar for promises with an ApiResponse as its data.
 */
export type ApiPromise<T> = Promise<ApiResponse<T>>

/**
 * Helper function to create successful results.
 * @param data
 */
export function successResult(data?: unknown): ApiPromise<unknown> {
    return Promise.resolve(
        new ApiResponse(ApiResponseStatus.SUCCESS, data || null)
    )
}


/**
 * Interface for an API Client.
 */
export interface ApiClientInterface {
    /**
     * Performs a GET request where T is the expected payload in the response.
     * @param params
     */
    get<T>(params: ApiClientRequestParametersInterface): ApiPromise<T>

    /**
     * Performs a POST request where T is the expected payload in the response.
     * @param params
     */
    post<T>(params: ApiClientRequestParametersInterface): ApiPromise<T>
}

/**
 * Represents the parameters of a request.
 */
export interface ApiClientRequestParametersInterface {
    url: string
    payload?: unknown
    headers?: unknown
}

/**
 * Implementation of an {@link ApiClientInterface} based on Axios.
 */
class AxiosApiClient implements ApiClientInterface {
    private axios: AxiosInstance

    constructor() {
        this.axios = axios.create({
            baseURL: process.env.VUE_APP_API_BASE_URL,
            timeout: 180 * 1000, // 3 minutes
            withCredentials: true
        })

        // Requests interceptor
        this.axios.interceptors.request.use((config) => {
            return config
        })

        // Response interceptor
        this.axios.interceptors.response.use(
            (response) => response,
            (error: AxiosError) => {
                if (error.response) {
                    console.error(error.response)
                } else {
                    console.error(error)
                }

                // Convert errors to API errors
                let errorType = error.code;
                let errorMessage = error.message;

                // Special cases
                if (errorMessage === "Network Error") {
                    errorType = "network_error"
                    errorMessage = "Could not connect to server."
                }

                // Timeout
                else if (errorType === "ECONNABORTED") {
                    errorType = "connection_timeout"
                }

                const clientError = {status: ApiResponseStatus.FAILED, error: {type: errorType, message: errorMessage}};
                console.debug(clientError)
                return Promise.reject(clientError)
            }
        )
    }

    get<T>(params: ApiClientRequestParametersInterface): ApiPromise<T> {
        return new Promise((resolve, reject) => {
            this.axios
                .get(params.url, { params: params.payload, headers: params.headers })
                .then((r) => resolve(r.data as ApiResponse<T>))
                .catch((error: ApiResponse<any>) => {
                    return reject(error as unknown as ApiResponse<T>)
                })
        })
    }

    post<T>(params: ApiClientRequestParametersInterface): ApiPromise<T> {
        return new Promise((resolve, reject) => {
            this.axios
                .post(params.url, params.payload, { headers: params.headers })
                .then((r: AxiosResponse) => resolve(r.data as ApiResponse<T>))
                .catch((error: ApiResponse<any>) => {
                    return reject(error as unknown as ApiResponse<T>)
                })
        })
    }
}

export const ApiClient = new AxiosApiClient()
