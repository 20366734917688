
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import Box from "@/AsicJungleAnalyticsUI/components/Box.vue";
import Stack from "@/AsicJungleAnalyticsUI/components/composites/Stack.vue";
import TextContent from "@/AsicJungleAnalyticsUI/components/content/TextContent.vue";
import Columns from "@/AsicJungleAnalyticsUI/components/composites/Columns.vue";
import ColumnSpacer from "@/AsicJungleAnalyticsUI/components/composites/ColumnSpacer.vue";
import Label from "@/AsicJungleAnalyticsUI/components/content/Label.vue";
import {DataSource} from "@/Classification/models/DataSource";
import DataSourcePage from "@/Classification/components/DataSourcesPage.vue";

@Component({
  components: {Label, ColumnSpacer, Columns, TextContent, Stack, Box}
})
export default class DataSourcesList extends Vue {

  @Prop({type: Array, required: true, default: () => []})
  dataSources: DataSource[]

  selectedDataSource: DataSource = null

  selectDataSource(ds: DataSource): void {
    if (this.selectedDataSource && this.selectedDataSource.id == ds.id) {
      return
    }

    this.selectedDataSource = ds
    this.$emit('data-source-selected', ds)
  }

  formatDataSource(data: string): string {
    return data.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
}
